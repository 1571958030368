import React from 'react';
//import { Link as RouterLink, useNavigate } from 'react-router-dom';
//import * as Yup from 'yup';
//import { Formik } from 'formik';
import {
    Container
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import SRDetails from '../List/SRDetails';

const LoginView = () => {
    const dispatch = useDispatch()
    const SingleServiceData = useSelector(state => state.SingleServiceData);
    dispatch({ type: 'SERVICE_LANDING_PAGE', payload: false });
    console.log('LandingPage.SRData', SingleServiceData);

    return (
        <Container maxWidth="md">
            {SingleServiceData && (
                <SRDetails
                    ProgressList={false}
                    ContractorActionButtons={true}
                    CompanyActionButtons={true}
                    SREvent={SingleServiceData}
                />
            )}
        </Container>
    );
};

export default LoginView;
